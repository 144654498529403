import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { webSocketManager } from '../webSocket';
import { Message } from '../types';


const ChatWindow: React.FC = () => {
  const [message, setMessage] = useState('');
  const activeChat = useSelector((state: RootState) => state.messages.activeChat);
  const  me = useSelector((state: RootState) => state.auth.username);
  const myUser = useSelector((state: RootState) => state.users.users.find(aUser=>aUser.username === me));
  const messages = useSelector((state: RootState) => state.messages.messages)
  const  { username} = useSelector((state: RootState) => state.auth);
  const [selectedMessages,setSelectedMessages] = useState<Message[]>([])

  useEffect(() => {
    if (activeChat?.startsWith('user')) {
      const messagesArray1 = messages[`${activeChat}:user-${myUser?.id}`] || [];
      const messagesArray2 = messages[`user-${myUser?.id}:${activeChat}`] || [];
  
      // Combine the two arrays
      const combinedMessages = [...messagesArray1, ...messagesArray2];
      setSelectedMessages(combinedMessages);
    } else {
      const messagesArray = messages[`${activeChat}`] || [];
      setSelectedMessages(messagesArray)
    }
  }, [activeChat, myUser, me,messages]);
  

  const sendMessage = () => {
    if (activeChat && message.trim()) {
      webSocketManager.sendMessage(activeChat, username, message);
      setMessage('');
    }
  };

  if (!activeChat)
    return (
      <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
        <h5 className="text-muted">Select a chat to start messaging</h5>
      </div>
    );

  return (
    <div className="d-flex flex-column vh-100">
      <div className="flex-grow-1 overflow-auto bg-light p-3">
        {selectedMessages.map((msg, index) => (
          <div key={index} className="mb-3">
            <strong>{msg.sender}:</strong> {msg.content}{' '}
            <small className="text-muted">{msg.timestamp}</small>
          </div>
        ))}
      </div>
      <div className="bg-white border-top p-3 d-flex">
        <input
          type="text"
          className="form-control me-2"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type a message..."
        />
        <button className="btn btn-primary" onClick={sendMessage}>
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatWindow;
