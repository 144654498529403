import { MsgType,validTypes } from "./types";

export interface ParsedMessage {
  type: MsgType;
  id: number;
}

export function parseMessage(input: string): ParsedMessage | null {
  
  const parts = input.split('-');

  if (parts.length !== 2) {
    console.warn(`Invalid format: "${input}". Expected format "TYPE-ID".`);
    return null;
  }

  const [typeStr, idStr] = parts;
  const type = typeStr.toUpperCase() as MsgType;
  const id = Number(idStr);

  

  if (!validTypes.includes(type)) {
    console.warn(`Invalid type: "${typeStr}". Expected one of ${validTypes.join(', ')}.`);
    return null;
  }

  if (isNaN(id)) {
    console.warn(`Invalid ID: "${idStr}" is not a number.`);
    return null;
  }

  return { type, id };
}

