import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from '../webClient/axios'
import { RootState } from '../store';
// Define types for Auth State
interface User {
  id: number;
  givenName: string;
  sirName: string 
  username: string 
  email: string;
}

interface AuthState {
  username: string ;
  token: string | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: string | null;
}

// Initial State
const initialState: AuthState = {
  username: "",
  token: null,
  isAuthenticated: false,
  isLoading: false,
  error: null,
};

// Define types for Thunk arguments
interface LoginArgs {
  username: string;
  password: string;
}

interface RegisterArgs {
  username: string;
  email: string;
  password: string;
}

// Async Thunk: Login
export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async ({ username, password }: LoginArgs, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/auth/login', { username, password });
      return response.data; // Assuming API returns { user, token }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);

// Async Thunk: Register
export const registerUser = createAsyncThunk(
  'auth/registerUser',
  async ({ username, email, password }: RegisterArgs, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/auth/register', { username, email, password });
      return response.data; // Assuming API returns { user, token }
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Something went wrong');
    }
  }
);

interface ChangePasswordArgs {
  currentPassword: string;
  newPassword: string;
  token: string;
  user: string ; // Adjust type as necessary
}

interface ChangePasswordResponse {
  message: string;
}

export const changePassword = createAsyncThunk<
  ChangePasswordResponse, // Return type on success
  ChangePasswordArgs,     // Argument type
  { rejectValue: string } // Rejected value type
>(
  'auth/changePassword',
  async (args: ChangePasswordArgs, { rejectWithValue }) => {
    const { currentPassword, newPassword, token, user } = args;

    try {
      // Make the POST request using axios
      const response = await axios.post<ChangePasswordResponse>(
        '/api/auth/change-password', // URL
        { currentPassword, newPassword, user }, // Request body
        {
          headers: {
            'Content-Type': 'application/json',
            ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
          },
        }
      );

      // Return the response data directly
      return response.data;
    } catch (error: any) {
      // Check if the error is an axios error with a response
      if (error.response && error.response.data && error.response.data.message) {
        // Return the server-provided error message
        return rejectWithValue(error.response.data.message);
      } else if (error.message) {
        // Return a generic error message
        return rejectWithValue(error.message);
      } else {
        // Fallback error message
        return rejectWithValue('Failed to change password');
      }
    }
  }
);
// Auth Slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.username = "";
      state.token = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(changePassword.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(changePassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      // Password changed successfully - optionally update user data if needed
    })
    .addCase(changePassword.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string || 'Failed to change password';
    });
    // Login
    builder.addCase(loginUser.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(loginUser.fulfilled, (state, action: PayloadAction<{ username: string ; token: string }>) => {
      state.isLoading = false;
      state.username = action.payload.username ;
      state.token = action.payload.token;
      state.isAuthenticated = true;
    });
    builder.addCase(loginUser.rejected, (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // Register
    builder.addCase(registerUser.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(registerUser.fulfilled, (state, action: PayloadAction<{ username: string; token: string }>) => {
      state.isLoading = false;
      state.username = action.payload.username;
      state.token = action.payload.token;
      state.isAuthenticated = true;
    });
    builder.addCase(registerUser.rejected, (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
