import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  fetchMetaData,
  fetchThumbnailFiles,
  fetchOnePhotoFile,
  deletePhotoMetadata,
  Metadata,
} from '../slices/photos';
import { AppDispatch, RootState } from '../store';
import Navbar from '../components/Navbar';

interface EnlargedPhoto {
  file: string; // Base64 data URL
  metadata: Metadata;
}

function stripE(filename: string): string {
  return filename.replace(/\.[^/.]+$/, '');
}

const Photos: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const { token, username } = useSelector((state: RootState) => state.auth);
  const { metadata, thumbnails, photos, status, error } = useSelector((state: RootState) => state.photos);

  const [enlargedPhoto, setEnlargedPhoto] = useState<EnlargedPhoto | null>(null);
  const [hoveredThumbnail, setHoveredThumbnail] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      if (status === 'idle' && token && username) {
        await dispatch(fetchMetaData({ token, username })).unwrap();
        await dispatch(fetchThumbnailFiles({ token, username })).unwrap();
      }
    })();
  }, [dispatch, location]);

  const handleThumbnailClick = async (photoName: string) => {
    if (!token || !username) {
      console.error('Token or username is missing.');
      return;
    }

    try {
      let foundPhoto = photos.find((mediaFile) => mediaFile?.filename === photoName);

      if (!foundPhoto) {
        const result = await dispatch(fetchOnePhotoFile({ token, username, imageName: photoName })).unwrap();
        if (result.length === 0) {
          console.error('Fetched photo did not include dataUrl:', photoName);
          return;
        }
        foundPhoto = result[0];
      }

      const validMetadata =
        metadata.find((meta) => stripE(meta.fileName) === stripE(photoName)) || {
          id: 0,
          user: username || '',
          fileType: 'unknown',
          fileName: photoName,
          visibility: 'unknown',
          md5hash: 'unknown',
        };

      setEnlargedPhoto({
        file: foundPhoto.dataUrl || '',
        metadata: validMetadata,
      });
    } catch (err) {
      console.error('Error fetching photo:', err);
    }
  };

  const handleDeletePhoto = async (photoName: string) => {
    if (!token || !username) return;
    const foundMetadata = metadata.find((meta: Metadata) => stripE(meta.fileName) === stripE(photoName));
    try {
      if (foundMetadata) {
        await dispatch(deletePhotoMetadata({ token, metadata: foundMetadata }));
        console.info(`Deleted photo: ${photoName}`);
      } else {
        return;
      }
    } catch (err) {
      console.error('Error deleting photo:', err);
    }
  };

  const handleClose = () => setEnlargedPhoto(null);

  if (!token || !username) return <p>Please log in to view photos.</p>;
  if (status === 'loading') return <p>Loading photos...</p>;
  if (status === 'failed') return <p>Error: {error}</p>;

  return (
    <>
      <Navbar />
      <div style={{ paddingTop: '60px' }}> {/* Adjust padding to account for fixed navbar */}
        {/* Thumbnails */}
        <div
          className="photo-grid"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            justifyContent: 'center', // Center the grid
          }}
        >
          {thumbnails && thumbnails.length > 0 && thumbnails.map((thumbnail, index) => {
            const isHovered = hoveredThumbnail === thumbnail.filename;

            return (
              <div
                key={index}
                style={{
                  position: 'relative',
                  width: '150px',
                  height: '150px',
                  overflow: 'hidden',
                  cursor: 'pointer',
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  transition: 'z-index 0.3s ease',
                  zIndex: isHovered ? 10 : 0,
                }}
                onMouseEnter={() => setHoveredThumbnail(thumbnail.filename)}
                onMouseLeave={() => setHoveredThumbnail(null)}
                onClick={() => handleThumbnailClick(thumbnail.filename)}
              >
                <img
                  src={thumbnail.dataUrl}
                  alt={thumbnail.filename}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    transition: 'transform 0.3s ease',
                    transform: isHovered ? 'scale(1.2)' : 'scale(1)',
                  }}
                />
                {/* Overlay */}
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                    opacity: isHovered ? 1 : 0,
                    transition: 'opacity 0.3s ease',
                    pointerEvents: isHovered ? 'auto' : 'none', // Prevent interaction when not visible
                  }}
                >
                  <p style={{ margin: 0 }}>
                    {metadata?.find((meta: Metadata) => stripE(meta.fileName) === stripE(thumbnail.filename))?.fileName || 'Unknown'}
                  </p>
                  <button
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the thumbnail click event
                      handleDeletePhoto(thumbnail.filename);
                    }}
                    style={{
                      position: 'absolute',
                      bottom: '8px',
                      right: '8px',
                      padding: '4px 8px',
                      fontSize: '12px',
                      backgroundColor: 'red',
                      color: 'white',
                      border: 'none',
                      borderRadius: '4px',
                      cursor: 'pointer',
                    }}
                    aria-label={`Delete ${thumbnail.filename}`}
                  >
                    Delete
                  </button>
                </div>
              </div>
            );
          })}
        </div>

        {/* Enlarged Photo Modal */}
        {enlargedPhoto && (
          <div
            onClick={handleClose}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1000,
            }}
          >
            <div
              onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
              style={{
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '10px',
                textAlign: 'center',
                maxHeight: '90vh',
                maxWidth: '90vw',
                overflow: 'auto',
              }}
            >
              <img
                src={enlargedPhoto.file}
                alt={enlargedPhoto.metadata.fileName}
                style={{ maxHeight: '90vh', maxWidth: '90vw', borderRadius: '10px' }}
              />
              <p>{enlargedPhoto.metadata.fileName}</p>
              <button
                onClick={handleClose}
                style={{
                  marginTop: '10px',
                  padding: '8px 16px',
                  fontSize: '14px',
                  backgroundColor: '#333',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
                aria-label="Close enlarged photo modal"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Photos;
