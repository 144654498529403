// src/features/download/downloadSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../webClient/axios'


interface DownloadVideoArgs {
  url: string;
  token: string;
}

export const downloadVideo = createAsyncThunk(
  'download/fetchVideo',
  async ({ url, token }: DownloadVideoArgs, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        '/api/download/fetch-video',
        { url },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        }
      );

      return response.data; // Return the response data (e.g., file path or success details)
    } catch (error: any) {
      // Handle the error and return a rejected value
      return rejectWithValue(
        error.response?.data?.message || error.message || 'Failed to download video'
      );
    }
  }
);

// Initial state
const initialState = {
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  filePath: null as string | null,
  error: null as string | null,
};

// Slice
const downloadSlice = createSlice({
  name: 'download',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadVideo.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.filePath = null;
      })
      .addCase(downloadVideo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.filePath = action.payload.filePath; // Assuming the API response contains `filePath`
        state.error = null;
      })
      .addCase(downloadVideo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
        state.filePath = null;
      });
  },
});

export default downloadSlice.reducer;
