import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../webClient/axios'
import { logout } from './auth';


// Metadata interface
export interface Metadata {
  id: number;
  user: string;
  fileType: string;
  fileName: string;
  visibility: string;
  md5hash: string;
}

// Media file interface
export interface MediaFile {
  dataUrl: string; // Base64 data URL for rendering
  filename: string;
}

// State interface
interface MediaState {
  metadata: Metadata[];
  photos: MediaFile[]; // Array of MediaFile for photos
  thumbnails: MediaFile[]; // Array of MediaFile for thumbnails
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

// Initial state
const initialState: MediaState = {
  metadata: [],
  photos: [],
  thumbnails: [],
  status: 'idle',
  error: null,
};

// Thunk to fetch metadata
export const fetchMetaData = createAsyncThunk<Metadata[], { token: string; username: string }>(
  'media/fetchMetaData',
  async ({ token, username }, { rejectWithValue }) => {
    try {
      const response = await axios.get<Metadata[]>('/api/catalog/search', {
        headers: { Authorization: `Bearer ${token}` },
        params: { user: username },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Unable to fetch metadata');
    }
  }
);

export const deletePhotoMetadata = createAsyncThunk<
  string,
  {metadata: Metadata,token: string },        // Parameter type
  { rejectValue: string } // Rejection type
>(
  'media/deletePhotoMetaData',
  async ({ token, metadata }, { rejectWithValue }) => {
    try {
      const response = await axios.delete<string>('/api/catalog/delete', {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: metadata, // Send metadata as JSON in the request body
      });

      return metadata.fileName
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error || 'Unable to delete metadata');
    }
  }
);


// Thunk to fetch photo files
export const fetchPhotoFiles = createAsyncThunk<
  MediaFile[], // Return array of MediaFile objects
  { token: string; username: string }
>('media/fetchPhotoFiles', async ({ token, username }, { rejectWithValue }) => {
  try {
    const response = await axios.get<MediaFile[]>('/api/photo/fetch-all-photos', {
      headers: { Authorization: `Bearer ${token}` },
      params: { user: username },
    });
    return response.data; // Directly return parsed MediaFile array
  } catch (error: any) {
    return rejectWithValue(error.response?.data || 'Unable to fetch photos');
  }
});

export const fetchOnePhotoFile = createAsyncThunk<
  MediaFile[], // Return a single MediaFile object
  { token: string; username: string; imageName: string }
>(
  'media/fetchOnePhotoFile',
  async ({ token, username, imageName }, { rejectWithValue }) => {
    try {
      const response = await axios.get<MediaFile[]>('/api/photo/fetch-photo', {
        headers: { Authorization: `Bearer ${token}` },
        params: { user: username, image: imageName }, // Pass imageName as query param
      });
      return response.data; // Directly return the parsed MediaFile object
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Unable to fetch the photo');
    }
  }
);


// Thunk to fetch thumbnail files
export const fetchThumbnailFiles = createAsyncThunk<
  MediaFile[], // Return array of MediaFile objects
  { token: string; username: string }
>('media/fetchThumbnailFiles', async ({ token, username }, { rejectWithValue }) => {
  try {
    const response = await axios.get<MediaFile[]>('/api/photo/fetch-all-thumbnails', {
      headers: { Authorization: `Bearer ${token}` },
      params: { user: username },
    });
    return response.data
  } catch (error: any) {
    return rejectWithValue(error.response?.data || 'Unable to fetch thumbnails');
  }
});

// Media slice
const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Metadata
    builder
      .addCase(fetchMetaData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMetaData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.metadata = action.payload;
      })
      .addCase(fetchMetaData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    // Photos
    builder
      .addCase(fetchPhotoFiles.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPhotoFiles.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.photos = action.payload; // Store array of MediaFile objects
      })
      .addCase(fetchPhotoFiles.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

      builder
      .addCase(fetchOnePhotoFile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOnePhotoFile.fulfilled, (state, action) => {
        state.status = 'succeeded';
      
        const addIfUnique = (photo:MediaFile) => {
          // Check if the photo is already in the array (based on id or filename)
          return !state.photos.some(existingPhoto =>  existingPhoto.filename === photo.filename);
        };
      
        if (Array.isArray(action.payload)) {
          // Filter new photos to only include unique ones
          const uniquePhotos = action.payload.filter(addIfUnique);
          state.photos = [...state.photos, ...uniquePhotos];
        } else {
          // Add single photo if it is unique
          if (addIfUnique(action.payload)) {
            state.photos = [...state.photos, action.payload];
          }
        }
      })
      .addCase(fetchOnePhotoFile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    // Thumbnails
    builder
      .addCase(fetchThumbnailFiles.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchThumbnailFiles.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.thumbnails = action.payload; // Store array of MediaFile objects
      })
      .addCase(fetchThumbnailFiles.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
      builder
      .addCase(deletePhotoMetadata.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deletePhotoMetadata.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.thumbnails = state.thumbnails.filter(aThumNail=>aThumNail.filename !==action.payload); // Store array of MediaFile objects
      })
      .addCase(deletePhotoMetadata.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
      builder.addCase(logout, () => initialState);
  },
});

export default mediaSlice.reducer;
