// store/index.ts

import { configureStore, combineReducers, Action } from '@reduxjs/toolkit';
import authReducer from './slices/auth';
import uploadReducer from './slices/upload';
import photoReducer from './slices/photos';
import downloadReducer from './slices/download';
import messagesReducer from './slices/messages';
import docReducer from './slices/docks';
import movieReducer from './slices/movies';
import usersReducer from './slices/users';
import onlineUsersReducer from './slices/onlineUsers';
import topicsReducer from './slices/topics';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// Combine all slice reducers into an appReducer
const appReducer = combineReducers({
  auth: authReducer,
  upload: uploadReducer,
  photos: photoReducer,
  download: downloadReducer,
  messages: messagesReducer,
  docs: docReducer,
  movies: movieReducer,
  users: usersReducer,
  onlineUsers: onlineUsersReducer,
  topics: topicsReducer
});

// Define RootState type
export type RootState = ReturnType<typeof appReducer>;

// Configure persist settings
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'upload', 'download', 'messages', 'movies'], // Persist these slices
  // Exclude 'photos' and 'docs' to prevent them from being persisted
};

// Create a persisted reducer
const persistedReducer = persistReducer<RootState>(persistConfig, appReducer);

// Configure the Redux store with the persisted reducer
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore redux-persist actions
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }).concat(logger), // Add logger middleware
});

// Create a persistor
const persistor = persistStore(store);

// Export store and persistor
export { store, persistor };

// Export types if you're using TypeScript
export type AppDispatch = typeof store.dispatch;
