// src/features/onlineUsers/onlineUsersSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { OnlineUser } from '../types'
import axios from '../webClient/axios';
import { logout } from './auth';

interface OnlineUsersState {
  onlineUsers: OnlineUser[];
  loading: boolean;
  error: string | null;
}

const initialState: OnlineUsersState = {
  onlineUsers: [],
  loading: false,
  error: null,
};

// Thunk to fetch online users from the messaging-server
export const fetchOnlineUsers = createAsyncThunk<OnlineUser[]>(
  'onlineUsers/fetchOnlineUsers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<OnlineUser[]>('/api/messaging/users'); // Replace with actual URL
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data || 'Failed to fetch online users');
    }
  }
);

const onlineUsersSlice = createSlice({
  name: 'onlineUsers',
  initialState,
  reducers: {
    // Add synchronous reducers if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOnlineUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOnlineUsers.fulfilled, (state, action: PayloadAction<OnlineUser[]>) => {
        state.loading = false;
        state.onlineUsers = action.payload;
      })
      .addCase(fetchOnlineUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
      builder.addCase(logout, () => initialState);

  },
});

export default onlineUsersSlice.reducer;
