// src/features/users/usersSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../types';
import axios from '../webClient/axios';
import { logout } from './auth';

interface UsersState {
  users: User[];
  loading: boolean;
  error: string | null;
}

const initialState: UsersState = {
  users: [],
  loading: false,
  error: null,
};


// Thunk to fetch all users from the auth-server
export const fetchAllUsers = createAsyncThunk(
  'users/fetchAllUsers',
  async ({ token, username }: { token: string; username: string }, { rejectWithValue }) => {
    try {
      const response = await axios.get<User[]>('/api/auth/getAllUsers',{
        headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { username },
      }); 
      return response.data as User[]
    } catch (err: any) {
      return rejectWithValue(err.response?.data || 'Failed to fetch users');
    }
  }
);

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    // Add synchronous reducers if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllUsers.fulfilled, (state, action: PayloadAction<User[]>) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
      builder.addCase(logout, () => initialState);

  },
});

export default usersSlice.reducer;
