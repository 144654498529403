// src/features/messages/messagesSlice.ts

import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Message } from '../types';
import axios from '../webClient/axios';
import { logout } from './auth';

interface MessagesState {
  messages: Record<string, Message[]>; // e.g., { 'userID-topicID': [Message, ...] }
  activeChat: string | null;
  loading: boolean;
  error: string | null;
}

const initialState: MessagesState = {
  messages: {},
  activeChat: null,
  loading: false,
  error: null,
};



// Thunk to fetch messages for a specific chat from the messaging-server
export const fetchMessages = createAsyncThunk<
  { chatID: string; messages: Message[] },
  string,
  { rejectValue: string }
>('messages/fetchMessages', async (chatID, { rejectWithValue }) => {
  try {
    const response = await axios.get<Message[]>(
      `/api/messaging?chatID=${chatID}` // Replace with actual URL
    );
    return { chatID, messages: response.data };
  } catch (err: any) {
    return rejectWithValue(err.response?.data || 'Failed to fetch messages');
  }
});

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    addMessage: (
      state,
      action: PayloadAction<{ chatID: string; message: Message }>
    ) => {
      const { chatID, message } = action.payload;
      if (!state.messages[chatID]) state.messages[chatID] = [];
      state.messages[chatID].push(message);
    },
    setActiveChat: (state, action: PayloadAction<string | null>) => {
      state.activeChat = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessages.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchMessages.fulfilled,
        (
          state,
          action: PayloadAction<{ chatID: string; messages: Message[] }>
        ) => {
          state.loading = false;
          const { chatID, messages } = action.payload;
          state.messages[chatID] = messages;
        }
      )
      .addCase(fetchMessages.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
      builder.addCase(logout, () => initialState);

  },
});

export const { addMessage, setActiveChat } = messagesSlice.actions;

export default messagesSlice.reducer;
