// WebSocketManager.ts
import { store } from './store';
import { addMessage } from './slices/messages';
import { parseMessage, ParsedMessage } from './message';
import { MsgType } from './types';
//import * as crypto from 'crypto';
import * as crypto from 'crypto-js';
import auth from './slices/auth';



// Define interfaces for User and Topic
interface User {
  id: number;
  username: string;
  // Add other user properties if necessary
}

interface Topic {
  id: number;
  name: string;
  // Add other topic properties if necessary
}

// Define the structure of outgoing messages
interface OutgoingMessage {
  chatID: string;
  type: MsgType;
  sender: string;
  recipient: string; // Username for USER, topic name for TOPIC, or "BROADCAST"
  content: string;
  timestamp: string; // RFC3339 format
}

// Define the structure of authentication messages
interface AuthMessage {
  type: 'AUTH';
  content: string; // The authentication token
}

// Define the structure of incoming messages
interface IncomingAuthResponse {
  type: 'AUTH';
  success: boolean;
  // Add other properties if the server sends more data on auth
}

interface IncomingChatMessage {
  chatID: string;
  id: number;
  type: MsgType;
  sender: string;
  recipient: string; // Username for USER, topic name for TOPIC, or "BROADCAST"
  content: string; // Corrected from 'message' to 'content'
  timestamp: string; // RFC3339 format
}

type IncomingMessage = IncomingAuthResponse | IncomingChatMessage;

// WebSocketManager Class
class WebSocketManager {
  private socket: WebSocket | null = null;
  private myId: string = this.me()

  constructor() {
    this.connect();
  }

  /**
   * Establishes a WebSocket connection and sets up event handlers.
   */
  public connect(): void {
    const state = store.getState();
    this.socket = new WebSocket(`ws://kid.viktor.fun/api/ws?username=${encodeURIComponent(state.auth.username)}`);
   // this.socket = new WebSocket('ws://localhost/api/ws'); // Ensure you use the correct WS URL
    this.socket.onopen = this.handleOpen.bind(this);
    this.socket.onmessage = this.handleMessage.bind(this);
    this.socket.onclose = this.handleClose.bind(this);
    this.socket.onerror = this.handleError.bind(this);
  }

  private me():string {
    const state = store.getState();
    const me = state.users.users.find(aUser=>aUser.username === state.auth.username)
    return `user-${me?.id}`
  }

  /**
   * Handles the WebSocket 'open' event.
   */
  private handleOpen(): void {
    console.log('WebSocket connection opened');
    this.authenticate();
  }

  /**
   * Authenticates the WebSocket connection using the auth token from the Redux store.
   */
  private authenticate(): void {
    const state = store.getState();
    const token = state.auth.token;

    if (token) {
      const authMessage: AuthMessage = {
        type: 'AUTH',
        content: token,
      };
      this.socket?.send(JSON.stringify(authMessage));
    } else {
      console.error('No auth token available');
      this.socket?.close();
    }
  }

  /**
   * Handles incoming WebSocket messages.
   * @param event - The message event containing data from the server.
   */
  private handleMessage(event: MessageEvent): void {
    try {
      const data: IncomingMessage = JSON.parse(event.data);
      console.log("WebSocket received data:", data);

      if (this.isAuthResponse(data)) {
        this.handleAuthResponse(data);
      } else if (this.isChatMessage(data)) {
        this.handleChatMessage(data);
      } else {
        console.warn('Unknown message type:', data);
      }
    } catch (error) {
      console.error('Failed to parse incoming WebSocket message:', error);
    }
  }

  /**
   * Type Guard for IncomingAuthResponse
   */
  private isAuthResponse(data: IncomingMessage): data is IncomingAuthResponse {
    return data.type === 'AUTH' && typeof (data as IncomingAuthResponse).success === 'boolean';
  }

  /**
   * Type Guard for IncomingChatMessage
   */
  private isChatMessage(data: IncomingMessage): data is IncomingChatMessage {
    return data.type !== 'AUTH';
  }

  /**
   * Processes authentication responses from the server.
   * @param data - The authentication response message.
   */
  private handleAuthResponse(data: IncomingAuthResponse): void {
    if (data.success) {
      console.log('WebSocket authenticated successfully');
    } else {
      console.error('WebSocket authentication failed');
      this.socket?.close();
    }
  }

  /**
   * Processes chat messages from the server and dispatches them to the Redux store.
   * @param data - The chat message received from the server.
   */
  private handleChatMessage(data: IncomingChatMessage): void {
    if (!data.content ) { // Ensure correct property names
      console.warn('Received malformed chat message:', data);
      return;
    }
    console.log("data",data)
    const chatID = crypto.SHA256(data.content).toString();
    store.dispatch(addMessage({ chatID: chatID, message: data }));
  }

  /**
   * Handles the WebSocket 'close' event and attempts to reconnect.
   */
  private handleClose(): void {
    console.log('WebSocket closed, reconnecting...');
    setTimeout(() => this.connect(), 5000);
  }

  /**
   * Handles WebSocket errors by logging them and closing the connection.
   * @param error - The error event.
   */
  private handleError(error: Event): void {
    console.error('WebSocket error:', error);
    this.socket?.close();
  }

  /**
   * Sends a chat message over the WebSocket connection.
   * @param content - The content of the message to send.
   */
  // WebSocketManager.ts

public sendMessage(activeChat: string, username: string, content: string): void {
  let recipientName: string | undefined;
  const state = store.getState();


  const dest: ParsedMessage | null = parseMessage(activeChat);
  if (!dest) {
    console.warn(`Failed to parse active chat identifier: "${activeChat}". Message not sent.`);
    return;
  }

  // Determine the recipient based on the message type
  switch (dest.type) {
    case 'USER': {
      const user = store.getState().users.users.find(user => user.id === dest.id);
      if (!user) {
        console.warn(`User with ID ${dest.id} not found. Message not sent.`);
        return;
      }
      recipientName = user.username;
      break;
    }

    case 'TOPIC': {
      const topic = store.getState().topics.topics.find(topic => topic.id === dest.id);
      if (!topic) {
        console.warn(`Topic with ID ${dest.id} not found. Message not sent.`);
        return;
      }
      recipientName = topic.name;
      break;
    }

    // Handle other MsgType cases if necessary
    default:
      console.warn(`Unsupported message type: "${dest.type}". Message not sent.`);
      return;
  }

  //const chatID = crypto.SHA256(content).toString();
  const chatID = state.messages.activeChat?.startsWith('user') ? `${this.me()}:${state.messages.activeChat}`:
  state.messages.activeChat

  const message: OutgoingMessage = {
    chatID: chatID || '',
    type: dest.type,
    sender: username,
    recipient: recipientName,
    content,
    timestamp: new Date().toISOString(),
  };


  if (this.socket?.readyState === WebSocket.OPEN) {
    console.log("message", message);
    this.socket.send(JSON.stringify(message));
    store.dispatch(addMessage({ chatID: chatID || '', message: message  }));

  } else {
    console.error('WebSocket is not open. Ready state:', this.socket?.readyState);
  }
}

}

export const webSocketManager = new WebSocketManager();
