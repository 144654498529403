// src/types.ts

export interface User {
    id: number;
    username: string;
    // Exclude password and other sensitive information
  }
  
  export interface OnlineUser {
    id: number;
    username: string;
    // Additional fields if necessary
  }
  
  export interface Topic {
    id: number;
    name: string;
  }
  
  // src/types/index.ts

export type MsgType = 'USER' | 'TOPIC' | 'SYSTEM' | 'BROADCAST' | 'AUTH' | 'AUTH_FAILURE' |"AUTH_SUCCESS";

export  const  validTypes: MsgType[] = [
    'USER',
    'TOPIC',
    'SYSTEM',
    'BROADCAST',
    'AUTH',
    'AUTH_FAILURE',
    'AUTH_SUCCESS',
  ];

export interface Message {
  id?: number;
  type: MsgType;
  sender: string;
  recipient: string;
  content: string;
  timestamp: string; // Consider using Date if you parse it later
}
