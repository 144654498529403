import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from  '../webClient/axios';
import {stripExtension} from '../util'



interface MediaFile {
    dataUrl: string; // Base64 data URL for rendering
    filename: string;
  }
  
// Define types for the slice
export interface Metadata {
  id: number;
  user: string;
  fileType: string;
  fileName: string;
  visibility: string;
  md5hash: string;
}

export interface Thumbnail {
  filename: string;
  dataUrl: string;
}

export interface Movie {
  filename: string;
  url: string; // HLS URL
}

export interface MoviesState {
  metadata: Metadata[];
  thumbnails: Thumbnail[];
  movies: Movie[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

// Initial state
const initialState: MoviesState = {
  metadata: [],
  thumbnails: [],
  movies: [],
  status: 'idle',
  error: null,
};

// Async actions
export const fetchMetaData = createAsyncThunk(
  'movies/fetchMetaData',
  async ({ token, username }: { token: string; username: string }) => {
    const response = await axios.get(`/api/catalog/search`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { fileType: 'movie' },
    });
    return response.data;
  }
);

export const deleteMovieMetadata = createAsyncThunk<
  string,
  {metadata: Metadata,token: string },        // Parameter type
  { rejectValue: string } // Rejection type
>(
  'movies/deleteMetaData',
  async ({ token, metadata }, { rejectWithValue }) => {
    try {
      const response = await axios.delete<string>('/api/catalog/delete', {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: metadata, // Send metadata as JSON in the request body
      });

      return metadata.fileName
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error || 'Unable to delete metadata');
    }
  }
);




export const fetchThumbnailFiles = createAsyncThunk<
  MediaFile[], // Return array of MediaFile objects
  { token: string; username: string }
>(
  'movies/fetchThumbnailFiles',
  async ({ token, username }, { rejectWithValue }) => {
    try {
      const response = await axios.get<MediaFile[]>('/api/stream/fetch-all-snapshots', {
        headers: { Authorization: `Bearer ${token}` },
        params: { username },
      });
      return response.data; // Directly return parsed MediaFile array
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Unable to fetch snapshots');
    }
  }
);

export const fetchMovieFile = createAsyncThunk(
  'movies/fetchMovieFile',
  async ({ token, username, movieName }: { token: string; username: string; movieName: string }) => {
    const response = await axios.get(`/api/stream/file`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { username, movieName },
    });
    return response.data;
  }
);

// Create the slice
const moviesSlice = createSlice({
  name: 'movies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Fetch metadata
    builder.addCase(fetchMetaData.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchMetaData.fulfilled, (state, action: PayloadAction<Metadata[]>) => {
      state.status = 'succeeded';
      state.metadata = action.payload;
    });
    builder.addCase(fetchMetaData.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || 'Failed to fetch metadata';
    });

    // Fetch thumbnail files
    builder.addCase(fetchThumbnailFiles.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchThumbnailFiles.fulfilled, (state, action: PayloadAction<Thumbnail[]>) => {
      state.status = 'succeeded';
      state.thumbnails = action.payload;
    });
    builder.addCase(fetchThumbnailFiles.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || 'Failed to fetch thumbnails';
    });

    // Fetch movie file
    builder.addCase(fetchMovieFile.pending, (state) => {
      state.status = 'loading';
    });
    builder.addCase(fetchMovieFile.fulfilled, (state, action: PayloadAction<Movie[]>) => {
      state.status = 'succeeded';
      state.movies.push(...action.payload); // Add to existing movies
    });
    builder.addCase(fetchMovieFile.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message || 'Failed to fetch movie';
    });
    builder.addCase(deleteMovieMetadata.pending, (state) => {
        state.status = 'loading';
      });
      builder.addCase(deleteMovieMetadata.fulfilled, (state, action: PayloadAction<string>) => {
        state.status = 'succeeded';
        state.movies = state.movies.filter(
            (movie) => movie.filename !== action.payload
          );
        state.thumbnails.filter(
          (aThumbnail)=>stripExtension(aThumbnail.filename) !== stripExtension(action.payload) )
      });
      builder.addCase(deleteMovieMetadata.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch movie';
      });
  },
});

// Export the reducer for the store
export default moviesSlice.reducer;
