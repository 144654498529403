import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../slices/auth';
import { AppDispatch, RootState } from '../store';
import background from '../assets/registerUser.webp'; // Adjust path as needed
import Navbar from '../components/Navbar';

const Register: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, error } = useSelector((state: RootState) => state.auth);

  const [username, setUsername] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(registerUser({ username, email, password }));
  };

  return (
    <>
      <Navbar />
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
          margin: 0,
        }}
      >
        <div
          style={{
            maxWidth: '400px',
            width: '100%',
            padding: '20px',
            border: '1px solid rgba(255,255,255,0.5)',
            borderRadius: '10px',
            background: 'rgba(0,0,0,0.3)',
            color: '#fff',
            textAlign: 'center',
          }}
        >
          <h3 style={{ marginBottom: '20px' }}>Register</h3>
          {error && (
            <p
              style={{
                color: '#ff4d4f',
                background: 'rgba(255,0,0,0.1)',
                padding: '10px',
                borderRadius: '5px',
                marginBottom: '10px',
              }}
            >
              Registering the user did not work. Please try again.
            </p>
          )}
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              style={{
                width: '100%',
                padding: '10px',
                marginBottom: '10px',
                border: '1px solid rgba(255, 255, 255, 0.7)',
                borderRadius: '5px',
                background: 'rgba(0, 0, 0, 0.4)',
                color: '#fff',
                outline: 'none',
                textAlign: 'center',
                fontSize: '16px',
              }}
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={{
                width: '100%',
                padding: '10px',
                marginBottom: '10px',
                border: '1px solid rgba(255, 255, 255, 0.7)',
                borderRadius: '5px',
                background: 'rgba(0, 0, 0, 0.4)',
                color: '#fff',
                outline: 'none',
                textAlign: 'center',
                fontSize: '16px',
              }}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              style={{
                width: '100%',
                padding: '10px',
                marginBottom: '20px',
                border: '1px solid rgba(255, 255, 255, 0.7)',
                borderRadius: '5px',
                background: 'rgba(0, 0, 0, 0.4)',
                color: '#fff',
                outline: 'none',
                textAlign: 'center',
                fontSize: '16px',
              }}
            />
            <button
              type="submit"
              disabled={isLoading}
              style={{
                width: '100%',
                padding: '10px',
                backgroundColor: isLoading ? 'rgba(150,150,150,0.8)' : 'rgba(0,123,255,0.8)',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: isLoading ? 'not-allowed' : 'pointer',
              }}
            >
              {isLoading ? 'Registering...' : 'Register'}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Register;
