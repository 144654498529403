// src/components/Sidebar.tsx

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store';
import { setActiveChat } from '../slices/messages';
import { fetchAllUsers } from '../slices/users';
import { fetchOnlineUsers } from '../slices/onlineUsers';
import { fetchTopics, addTopic, removeTopic } from '../slices/topics';
import { User, Topic } from '../types';
import { Modal, Button, Form } from 'react-bootstrap';

const Sidebar: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { token, username } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (token && username) {
        dispatch(fetchAllUsers({token,username}));
    }   
  }, [dispatch,token,username]);

  useEffect(() => {
    dispatch(fetchOnlineUsers());
    dispatch(fetchTopics());
  }, [dispatch]);

  // Selectors
  const users = useSelector((state: RootState) => state.users.users);
  const onlineUsers = useSelector((state: RootState) => state.onlineUsers.onlineUsers);
  const topics = useSelector((state: RootState) => state.topics.topics);
  const activeChat = useSelector((state: RootState) => state.messages.activeChat);

  // State for Add Topic Modal
  const [showAddTopic, setShowAddTopic] = useState(false);
  const [newTopicName, setNewTopicName] = useState('');

  // State for Remove Topic Modal
  const [showRemoveTopic, setShowRemoveTopic] = useState(false);
  const [topicToRemove, setTopicToRemove] = useState<Topic | null>(null);

  // Handlers for Add Topic
  const handleAddTopic = () => {
    setShowAddTopic(true);
  };

  const handleAddTopicClose = () => {
    setShowAddTopic(false);
    setNewTopicName('');
  };

  const handleAddTopicSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (newTopicName.trim() !== '') {
      dispatch(addTopic(newTopicName.trim()));
      handleAddTopicClose();
    }
  };

  // Handlers for Remove Topic
  const handleRemoveTopic = (topic: Topic) => {
    setTopicToRemove(topic);
    setShowRemoveTopic(true);
  };

  const handleRemoveTopicClose = () => {
    setShowRemoveTopic(false);
    setTopicToRemove(null);
  };

  const handleRemoveTopicConfirm = () => {
    if (topicToRemove) {
      dispatch(removeTopic(topicToRemove.id));
      handleRemoveTopicClose();
    }
  };

  // Utility to check if a user is online
  const isUserOnline = (user: User): boolean => {
    return onlineUsers.some((onlineUser) => onlineUser.id === user.id);
  };

  return (
    <div className="d-flex flex-column bg-dark text-light vh-100 p-3" style={{ width: '300px' }}>
      <h5 className="text-center mb-4">Chats</h5>

      {/* Users Section */}
      <div className="mb-4">
        <h6>Users</h6>
        <ul className="list-unstyled">
          {users.map((user) => (
            <li key={user.id}>
              <button
                className={`btn btn-link text-light text-start w-100 d-flex justify-content-between align-items-center ${
                  activeChat === `user-${user.id}` ? 'active bg-primary rounded' : ''
                }`}
                onClick={() => dispatch(setActiveChat(`user-${user.id}`))}
              >
                <span>{user.username}</span>
                {isUserOnline(user) && (
                  <span className="badge bg-success rounded-pill">Online</span>
                )}
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Topics Section */}
      <div className="mb-4">
        <div className="d-flex justify-content-between align-items-center">
          <h6>Topics</h6>
          <Button variant="success" size="sm" onClick={handleAddTopic}>
            +
          </Button>
        </div>
        <ul className="list-unstyled">
          {topics.map((topic) => (
            <li key={topic.id} className="d-flex justify-content-between align-items-center">
              <button
                className={`btn btn-link text-light text-start w-100 ${
                  activeChat === `topic-${topic.id}` ? 'active bg-primary rounded' : ''
                }`}
                onClick={() => dispatch(setActiveChat(`topic-${topic.id}`))}
              >
                {topic.name}
              </button>
              <Button
                variant="danger"
                size="sm"
                onClick={() => handleRemoveTopic(topic)}
                className="ms-2"
              >
                -
              </Button>
            </li>
          ))}
        </ul>
      </div>

      {/* Add Topic Modal */}
      <Modal show={showAddTopic} onHide={handleAddTopicClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add New Topic</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleAddTopicSubmit}>
          <Modal.Body>
            <Form.Group controlId="formTopicName">
              <Form.Label>Topic Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter topic name"
                value={newTopicName}
                onChange={(e) => setNewTopicName(e.target.value)}
                required
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleAddTopicClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Add Topic
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Remove Topic Confirmation Modal */}
      <Modal show={showRemoveTopic} onHide={handleRemoveTopicClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Remove Topic</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {topicToRemove && (
            <p>
              Are you sure you want to remove the topic <strong>{topicToRemove.name}</strong>?
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleRemoveTopicClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleRemoveTopicConfirm}>
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Sidebar;
