// src/features/topics/topicsSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from '../webClient/axios';
import { Topic } from '../types';

// Define the base URL for the messaging server

// Thunk to fetch topics from the messaging-server
export const fetchTopics = createAsyncThunk<Topic[]>(
  'topics/fetchTopics',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<Topic[]>('/api/messaging/topics');
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data?.message || 'Failed to fetch topics');
    }
  }
);

// Thunk to add a new topic to the messaging-server
export const addTopic = createAsyncThunk<Topic, string, { rejectValue: string }>(
  'topics/addTopic',
  async (topicName, { rejectWithValue }) => {
    try {
      const response = await axios.post<Topic>('/api/messaging/topics', { name: topicName });
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response?.data?.message || 'Failed to add topic');
    }
  }
);
export const removeTopic = createAsyncThunk<
  number, // Return type on success
  number, // Argument type (topicId)
  { rejectValue: string } // Type for rejectWithValue
>(
  'topics/removeTopic',
  async (topicId, { rejectWithValue }) => {
    try {
      // Use template literals to include topicId in the URL path
      await axios.delete(`/api/messaging/topics`,{ params: { id: topicId } });
      
      // Return the deleted topic's ID to update the state
      return topicId;
    } catch (err: any) {
      // Check if the error has a response and a message
      const errorMessage =
        err.response?.data?.message || 'Failed to remove topic';
      
      // Use rejectWithValue to pass a custom error message
      return rejectWithValue(errorMessage);
    }
  }
);
// Define the TopicsState interface
interface TopicsState {
  topics: Topic[];
  loading: boolean;
  error: string | null;
}

// Initial state
const initialState: TopicsState = {
  topics: [],
  loading: false,
  error: null,
};

// Create the topics slice
const topicsSlice = createSlice({
  name: 'topics',
  initialState,
  reducers: {
    // Add synchronous reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchTopics
      .addCase(fetchTopics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTopics.fulfilled, (state, action: PayloadAction<Topic[]>) => {
        state.loading = false;
        state.topics = action.payload;
      })
      .addCase(fetchTopics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Handle addTopic
      .addCase(addTopic.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addTopic.fulfilled, (state, action: PayloadAction<Topic>) => {
        state.loading = false;
        state.topics.push(action.payload);
      })
      .addCase(addTopic.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })

      // Handle removeTopic
      .addCase(removeTopic.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removeTopic.fulfilled, (state, action: PayloadAction<number>) => {
        state.loading = false;
        state.topics = state.topics.filter((topic) => topic.id !== action.payload);
      })
      .addCase(removeTopic.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default topicsSlice.reducer;
