import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../slices/auth'; // Your login thunk
import { AppDispatch, RootState } from '../store'; // Your Redux types
import subtubeBackground from '../assets/subtube.webp'; // Import the background image



const Login: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, error, isAuthenticated,token } = useSelector((state: RootState) => state.auth);

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loginSuccess, setLoginSuccess] = useState<boolean>(false);
  useEffect(()=>{
    if (isAuthenticated) {
    }
  },[isAuthenticated,dispatch])

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(loginUser({ username, password }))
      .unwrap()
      .then(() => {
        setLoginSuccess(true);
      })
      .catch(() => {
        setLoginSuccess(false);
      });
  };

  useEffect(() => {
    if (loginSuccess) {
      setTimeout(() => {
        window.location.reload(); // Simulate a reload or redirect
      }, 1000);
    }
  }, [loginSuccess]);

  return (
    <div className="container d-flex align-items-center justify-content-center vh-100"
    style={{
      backgroundImage: `url(${subtubeBackground})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    }}>
      <div className="card shadow-lg p-4"
       style={{
        maxWidth: '400px',
        width: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent white background
        backdropFilter: 'blur(2px)', // Adds a blur effect to the background
        border: '1px solid rgba(255, 255, 255, 0.3)', // Optional border
      }}>
        <h3 className="text-center mb-4">Welcome Back!</h3>
        <form onSubmit={handleSubmit}>
          {error && (
            <div className="alert alert-danger" role="alert">
              {typeof error === 'string' ? error : (error as any).message}
            </div>
          )}
          {loginSuccess && (
            <div className="alert alert-success" role="alert">
              Login successful! Redirecting...
            </div>
          )}
          <div className="form-group mb-3">
            <label htmlFor="username" className="form-label">
              Username
            </label>
            <input
              type="text"
              id="username"
              className="form-control"
              placeholder="Enter your username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input
              autoComplete="current-password" 
              type="password"
              id="password"
              className="form-control"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary w-100" disabled={isLoading}>
            {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Login'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
