import  { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from '../slices/auth'; // Import logout action

const Navbar = () => {
  const location = useLocation();
  const currentRoute = location.pathname;
  const dispatch = useDispatch();
  
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Check if Ctrl key is pressed along with 'n' or 'N'
      if (e.ctrlKey && (e.key === 'n' || e.key === 'N')) {
        setVisible((prev) => !prev);
      }
    };
    
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleLogout = () => {
    console.log("Logged out");
    dispatch(logout());
  };

  const cards = [
    { title: 'Movies', icon: 'bi-film', route: '/movies' },
    { title: 'Photos', icon: 'bi-image', route: '/photos' },
    { title: 'SyncTube', icon: 'bi-play-circle', route: '/synctube' },
    { title: 'Music', icon: 'bi-music-note', route: '/music' },
    { title: 'Upload', icon: 'bi-cloud-upload', route: '/upload' },
    { title: 'Messaging', icon: 'bi-chat', route: '/messaging' },
    { title: 'Documents', icon: 'bi-file-text', route: '/documents' },
    { title: 'Change Password', icon: 'bi-lock', route: '/change-password' },
    { title: 'Register', icon: 'bi-person-plus', route: '/register' },
    { title: 'Dashboard', icon: 'bi-speedometer2', route: '/dashboard' },
    { title: 'Logout', icon: 'bi-box-arrow-right', action: handleLogout },
  ];

  // If navbar is not visible, return null so it doesn't render at all
  if (!visible) return null;

  return (
    <nav
      className="navbar navbar-expand-lg bg-dark"
      style={{
        position: 'fixed',           // Make the navbar fixed
        top: 0,                      // Position it at the top
        left: 0,                     // Align it to the left
        width: '100%',               // Span the full width
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        padding: "10px",
        backgroundColor: "rgba(0, 0, 0, 0.9)", // Slightly more opaque for better visibility
        backdropFilter: "blur(5px)",
        borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
        zIndex: 1000,                // Ensure it stays above other elements
        boxSizing: 'border-box',     // Include padding in width calculations
      }}
    >
      {cards.map((card, index) => {
        if (card.route === currentRoute) return null;

        if (card.title === "Logout") {
          return (
            <button
              key={index}
              className="btn btn-dark d-flex flex-column align-items-center"
              onClick={card.action}
              style={{ 
                border: "none", 
                color: "white", 
                background: 'none', // Remove default button background
                padding: '0',
                cursor: 'pointer',
              }}
              aria-label="Logout"
            >
              <i className={`bi ${card.icon}`} style={{ fontSize: "1.5rem" }}></i>
              <span>{card.title}</span>
            </button>
          );
        }

        return (
          <NavLink
            key={index}
            to={card.route || "#"}
            className="text-light text-decoration-none d-flex flex-column align-items-center"
            style={({ isActive }) => ({
              padding: "5px",
              transition: 'transform 0.2s', // Optional: Add a slight scale effect on hover
              transform: isActive ? 'scale(1.1)' : 'scale(1)',
              color: isActive ? '#FFD700' : 'white', // Example: Change color if active
            })}
            aria-label={card.title}
          >
            <i className={`bi ${card.icon}`} style={{ fontSize: "1.5rem" }}></i>
            <span>{card.title}</span>
          </NavLink>
        );
      })}
    </nav>
  );
};

export default Navbar;
