import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchMetaData,
  fetchThumbnailFiles,
  Metadata,
  deleteMovieMetadata,
  Thumbnail
} from '../slices/movies';
import { AppDispatch, RootState } from '../store';
import Navbar from '../components/Navbar';
import HlsPlayer from '../components/HlsPlayer';

function stripExtension(filename: string): string {
  return filename.replace(/\.[^/.]+$/, '');
}

function filterThumbnails(thumbnails: Thumbnail[], metadata: Metadata[]): Thumbnail[] {
  return thumbnails.filter(thumbnail => {
    const baseName = stripExtension(thumbnail.filename);
    return metadata.some(meta => stripExtension(meta.fileName) === baseName);
  });
}

const Movies: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const { token, username } = useSelector((state: RootState) => state.auth);
  const { metadata, thumbnails, status, error } = useSelector((state: RootState) => state.movies);

  const [selectedMovie, setSelectedMovie] = useState<{
    file: string;
    metadata: Metadata;
  } | null>(null);
  const [hoveredThumbnail, setHoveredThumbnail] = useState<string | null>(null);
  const [filteredThumbnails, setFilteredThumbnails] = useState<Thumbnail[]>([]);

  // First effect: Fetch data when idle and credentials are available
  useEffect(() => {
    const fetchData = async () => {
      if (status === 'idle' && token && username) {
        try {
          await dispatch(fetchMetaData({ token, username })).unwrap();
          await dispatch(fetchThumbnailFiles({ token, username })).unwrap();
          // After this, metadata and thumbnails in the store should be updated
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();
  }, [dispatch, status, token, username, location]);

  // Second effect: Filter thumbnails whenever metadata or thumbnails change
  useEffect(() => {
    if (metadata?.length > 0 && thumbnails?.length > 0) {
      const filtered = filterThumbnails(thumbnails, metadata);
      setFilteredThumbnails(filtered);
    } else {
      setFilteredThumbnails([]);
    }
  }, [metadata, thumbnails]);

  const handleError = (message: string, err?: unknown) => {
    console.error(message, err);
  };

  const handleThumbnailClick = (movieName: string) => {
    if (!token || !username) {
      handleError('Token or username is missing.');
      return;
    }

    const validMetadata =
      metadata.find((meta: Metadata) => stripExtension(meta.fileName) === stripExtension(movieName)) || null;

    setSelectedMovie({
      file: `http://kid.viktor.fun/api/stream/hls/${username}/${movieName}/index.m3u8`,
      metadata: validMetadata || {
        id: 0,
        user: username,
        fileType: 'unknown',
        fileName: movieName,
        visibility: 'unknown',
        md5hash: 'unknown',
      },
    });
  };

  const handleClosePlayer = () => setSelectedMovie(null);

  const handleDelete = async () => {
    if (!selectedMovie) return;
    try {
      await dispatch(deleteMovieMetadata({ token: token || '', metadata: selectedMovie.metadata })).unwrap();
      setSelectedMovie(null);

      // Refetch data after deletion
      await dispatch(fetchMetaData({ token: token || '', username: username || '' })).unwrap();
      await dispatch(fetchThumbnailFiles({ token: token || '', username: username || '' })).unwrap();
      // Filtering will be triggered by the second useEffect once state updates
    } catch (err) {
      handleError('Error deleting movie:', err);
    }
  };

  const handleThumbnailDelete = async (
    e: React.MouseEvent<HTMLButtonElement>,
    thumbnailFilename: string
  ) => {
    e.stopPropagation();
    if (!token || !username) {
      handleError('Token or username is missing.');
      return;
    }

    const targetMetadata = metadata.find(
      (meta: Metadata) => stripExtension(meta.fileName) === stripExtension(thumbnailFilename)
    );

    if (!targetMetadata) {
      handleError('Metadata not found for the selected thumbnail.');
      return;
    }

    try {
      await dispatch(deleteMovieMetadata({ token: token || '', metadata: targetMetadata })).unwrap();

      // Refetch data after deletion
      await dispatch(fetchMetaData({ token: token || '', username: username || '' })).unwrap();
      await dispatch(fetchThumbnailFiles({ token: token || '', username: username || '' })).unwrap();
      // Filtering will update `filteredThumbnails` via the second useEffect
    } catch (err) {
      handleError('Error deleting movie:', err);
    }
  };

  //if (!token || !username) return <p>Please log in to view movies.</p>;
  //if (status === 'loading') return <p>Loading movies...</p>;
  //if (status === 'failed') return <p>Error: {error}</p>;

  return (
    <>
      <Navbar />
      <div style={{ position: 'relative' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', position: 'relative' }}>
          {filteredThumbnails.length === 0 && <p>No matching thumbnails found.</p>}
          {filteredThumbnails.map((thumbnail: Thumbnail, index: number) => {
            const currentMetadata = metadata.find(
              (meta: Metadata) =>
                stripExtension(meta.fileName) === stripExtension(thumbnail.filename)
            );

            const displayFileName = currentMetadata?.fileName || thumbnail.filename;
            const displayVisibility = currentMetadata?.visibility || 'Unknown';
            const isHovered = hoveredThumbnail === thumbnail.filename;

            return (
              <div
                key={index}
                style={{
                  position: 'relative',
                  width: '150px',
                  height: '150px',
                  overflow: 'visible',
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  cursor: 'pointer',
                }}
                onMouseEnter={() => setHoveredThumbnail(thumbnail.filename)}
                onMouseLeave={() => setHoveredThumbnail(null)}
                onClick={() => handleThumbnailClick(thumbnail.filename)}
              >
                <img
                  src={thumbnail.dataUrl}
                  alt={thumbnail.filename}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                />

                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    width: '225px',
                    height: '225px',
                    backgroundColor: 'rgba(0,0,0,0.8)',
                    borderRadius: '8px',
                    transform: 'translate(-50%, -50%)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    padding: '10px',
                    fontSize: '12px',
                    wordBreak: 'break-word',
                    color: 'white',
                    zIndex: isHovered ? 9999 : -1,
                    opacity: isHovered ? 1 : 0,
                    visibility: isHovered ? 'visible' : 'hidden',
                    transition: 'opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, z-index 0.2s ease-in-out',
                  }}
                >
                  <p style={{ margin: '5px 0' }}>{displayFileName}</p>
                  <p style={{ margin: '5px 0' }}>{displayVisibility}</p>
                  <button
                    onClick={(e) => handleThumbnailDelete(e, thumbnail.filename)}
                    style={{
                      marginTop: '10px',
                      padding: '5px 10px',
                      backgroundColor: 'red',
                      border: 'none',
                      color: 'white',
                      cursor: 'pointer',
                      borderRadius: '4px',
                      whiteSpace: 'nowrap',
                      fontSize: '12px',
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            );
          })}
        </div>

        {selectedMovie && (
          <div
            onClick={handleClosePlayer}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1000,
            }}
          >
            <div
              style={{
                position: 'relative',
                backgroundColor: 'black',
                padding: '20px',
                borderRadius: '10px',
                textAlign: 'center',
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <HlsPlayer
                url={selectedMovie.file}
                token={token|| ''}
                controls
                style={{ width: '100%', maxHeight: '90vh', maxWidth: '90vw' }}
              />
              <button
                onClick={handleClosePlayer}
                style={{
                  position: 'absolute',
                  top: '10px',
                  right: '10px',
                  backgroundColor: 'red',
                  color: 'white',
                  border: 'none',
                  padding: '5px 10px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  fontSize: '12px',
                }}
              >
                Close
              </button>
              <button
                onClick={handleDelete}
                style={{
                  position: 'absolute',
                  bottom: '8px',
                  right: '8px',
                  padding: '4px 8px',
                  fontSize: '12px',
                  backgroundColor: 'red',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
              >
                Delete
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Movies;
