import React from 'react';
import { useSelector } from 'react-redux';
import { useRoutes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import FileDropZone from './pages/FileDropZone';
import Photos from './pages/Photo';
import Synctube from './pages/Synctube';
import Movies from './pages/Movies';
//import Music from './pages/Music';
import Messaging from './pages/Messaging';
import Documents from './pages/Documents';
//import ChangePassword from './pages/ChangePassword';
import { RootState } from './store';
import Logout from './components/Logout';
import ChangePassword from './pages/ChangePassword';

function handleLogout() {

}

const AppContent: React.FC = () => {
  const { isAuthenticated, isLoading } = useSelector((state: RootState) => state.auth);

  // Define routes
  const routes = useRoutes([
    {
      path: '/',
      element: isAuthenticated ? <Navigate to="/dashboard" replace /> : <Login />,
    },
    {
      path: '/register',
      element: isAuthenticated ?   <Register />:<Navigate to="/" replace />,
    },
    {
      path: '/dashboard',
      element: isAuthenticated ? <Dashboard /> : <Navigate to="/" replace />,
    },
    {
      path: '/upload',
      element: isAuthenticated ? <FileDropZone /> : <Navigate to="/" replace />,
    },
    {
      path: '/photos',
      element: isAuthenticated ? <Photos /> : <Navigate to="/" replace />,
    },
    {
      path: '/synctube',
      element: isAuthenticated ? <Synctube /> : <Navigate to="/" replace />,
    },
    {
      path: '/movies',
      element: isAuthenticated ? <Movies /> : <Navigate to="/" replace />,
    },
    {
      path: '/logout',
      element: <Logout onLogout={handleLogout} />, // handleLogout clears authentication state
    },
    /*
    {
      path: '/music',
      element: isAuthenticated ? <Music /> : <Navigate to="/" replace />,
    },
     {
      path: '/change-password',
      element: isAuthenticated ? <ChangePassword /> : <Navigate to="/" replace />,
    },
    */
    {
      path: '/change-password',
      element: isAuthenticated ? <ChangePassword /> : <Navigate to="/" replace />,
    },
    {
      path: '/messaging',
      element: isAuthenticated ? <Messaging /> : <Navigate to="/" replace />,
    },
    {
      path: '/documents',
      element: isAuthenticated ? <Documents /> : <Navigate to="/" replace />,
    },
    {
      path: '*',
      element: <Navigate to="/" replace />,
    },
  ]);

  // Show a loading indicator while authentication state is being determined
  if (isLoading) {
    return <div className="loading text-center">Loading...</div>;
  }

  return routes;
};

export default AppContent;
