import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UploadState {
    files: string[];
    loading: boolean;
    error: string | null;
}

const initialState: UploadState = {
    files: [],
    loading: false,
    error: null,
};

const uploadSlice = createSlice({
    name: "upload",
    initialState,
    reducers: {
        uploadFilesRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        uploadFilesSuccess: (state, action: PayloadAction<string[]>) => {
            state.loading = false;
            state.files = [...state.files, ...action.payload];
        },
        uploadFilesFailure: (state, action: PayloadAction<string | null>) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const { uploadFilesRequest, uploadFilesSuccess, uploadFilesFailure } = uploadSlice.actions;

export default uploadSlice.reducer;
