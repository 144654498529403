import React, { useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import ChatWindow from '../components/ChattWindow';
import { webSocketManager } from '../webSocket';
import Navbar from '../components/Navbar';

const Messaging: React.FC = () => {
  useEffect(() => {
    webSocketManager.connect();
  }, []);

  return (
    <>
    <Navbar/>
    <div className="container-fluid vh-100">
      <div className="row h-100">
        <div className="col-3 bg-dark">
          <Sidebar />
        </div>
        <div className="col-9">
          <ChatWindow />
        </div>
      </div>
    </div>
    </>
  );
};

export default Messaging;
