import axios, { AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';

// Create an Axios instance
const axiosInstance: AxiosInstance = axios.create({
  baseURL: 'http://kid.viktor.fun', // Replace with your API base URL
  timeout: 5000, // Request timeout in milliseconds
  headers: {
    'Content-Type': 'application/json', // Default headers
  },
});

// Configure retry logic using axios-retry
axiosRetry(axiosInstance, {
  retries: 3, // Number of retry attempts
  retryDelay: (retryCount: number) => retryCount * 1000, // Exponential backoff: 1s, 2s, 3s
  retryCondition: (error:any ) =>
    axiosRetry.isNetworkOrIdempotentRequestError(error) ||
    error.response?.status >= 500, // Retry for network errors or 5xx status codes
});

/*
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Get token from storage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
*/

// Add response interceptor (e.g., handle errors globally)
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('API Error:', error.response || error.message);
    return Promise.reject(error);
  }
);

export default axiosInstance;
