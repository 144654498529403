// Logout.tsx

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch, persistor } from '../store'; // Adjust the import path as necessary

interface LogoutProps {
  onLogout: () => void;
}

const Logout: React.FC<LogoutProps> = ({ onLogout }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    // Run the logout logic (e.g., clear tokens, update auth state)
    onLogout();

    // Dispatch the RESET action to clear the Redux store
    dispatch({ type: 'RESET' });

    // Purge the persisted storage to ensure all data is cleared
    persistor.purge().then(() => {
      console.log('Persisted storage purged successfully.');
    }).catch((err) => {
      console.error('Error purging persisted storage:', err);
    });

    // Redirect to the home page after logout
    navigate('/', { replace: true });
  }, [onLogout, navigate, dispatch]);

  return null; // This component doesn't render any UI
};

export default Logout;
