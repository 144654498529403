import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation
import { useDispatch, useSelector } from 'react-redux'; // For Redux actions
import { logout } from '../slices/auth'; // Import logout action
import background from '../assets/dashboard.webp'; // Import the background image
import { AppDispatch, RootState } from '../store';
import { fetchMetaData as photoMetadata } from '../slices/photos';
import { fetchMetaData as docMetadata } from '../slices/docks';
import { fetchThumbnailFiles, fetchMetaData as movieMetadata } from '../slices/movies';



const Dashboard: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const { username,token} = useSelector((state: RootState) => state.auth);

    useEffect(() => {
          (async () => {
            try {
              await dispatch(photoMetadata({ token: token || '', username: username || '' }));
              await dispatch(docMetadata({ token: token || '', username: username || '' }));
              await dispatch(movieMetadata({ token: token || '', username: username || '' }));
              await dispatch(fetchThumbnailFiles({ token: token || '', username: username || '' }));
            } catch (error) {
              console.error('Metadata fetching failed', error);
              // Handle error scenario if needed
            }
          })();
        }
      , [navigate]);

    // Handle logout logic
    const handleLogout = () => {
        dispatch(logout()); // Clear auth state
        navigate('/'); // Redirect to login page
    };

    const cards = [
        { title: 'Movies', icon: 'bi-film', route: '/movies' },
        { title: 'Photos', icon: 'bi-image', route: '/photos' },
        { title: 'SyncTube', icon: 'bi-play-circle', route: '/synctube' },
        { title: 'Music', icon: 'bi-music-note', route: '/music' },
        { title: 'Upload', icon: 'bi-cloud-upload', route: '/upload' },
        { title: 'Messaging', icon: 'bi-chat', route: '/messaging' },
        { title: 'Documents', icon: 'bi-file-text', route: '/documents' },
        { title: 'Change Password', icon: 'bi-lock', route: '/change-password' },
        { title: 'Register', icon: 'bi-person-plus', route: '/register' },
        { title: 'Logout', icon: 'bi-box-arrow-right', action: handleLogout },
    ];

    return (
        <div
            className="container py-5"
            style={{
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                minHeight: '100vh',
            }}
        >
            <h2 className="text-center mb-4 text-black">
            {`Welcome to subtube `}
            <span style={{ fontStyle: 'italic', fontFamily: 'cursive' }}>{username}</span>
            </h2>
            <div className="row g-4">
                {cards.map((card, index) => (
                    <div key={index} className="col-md-4 d-flex justify-content-center">
                        <div
                            className="card h-100 shadow-lg text-center"
                            style={{
                                maxWidth: '400px',
                                width: '100%',
                                backgroundColor: 'rgba(255, 255, 255, 0.2)', // Transparent white
                                backdropFilter: 'blur(10px)', // Adds glassmorphism effect
                                border: '1px solid rgba(255, 255, 255, 0.3)', // Subtle border
                                borderRadius: '15px',
                                cursor: 'pointer',
                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                            }}
                            onClick={() => (card.route ? navigate(card.route) : card.action?.())}
                            onMouseEnter={(e) =>
                                (e.currentTarget.style.transform = 'scale(1.05)')
                            }
                            onMouseLeave={(e) =>
                                (e.currentTarget.style.transform = 'scale(1)')
                            }
                        >
                            <div className="card-body d-flex flex-column justify-content-center align-items-center text-white">
                                <i className={`bi ${card.icon} mb-3`} style={{ fontSize: '3rem' }}></i>
                                <h5 className="card-title">{card.title}</h5>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Dashboard;
