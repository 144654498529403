import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMetaData, fetchOneDocument, Document } from '../slices/docks';
import { AppDispatch, RootState } from '../store';
import Navbar from '../components/Navbar';
import { Metadata } from '../slices/photos';
import background from "../assets/documents.webp"; // Import the background image
import { useLocation } from 'react-router-dom';


function stripExtension(filename: string): string {
  return filename.replace(/\.[^/.]+$/, '');
}

const getFileIcon = (fileType: string): string => {
  if (fileType.includes('pdf')) return 'bi-file-earmark-pdf text-danger';
  if (fileType.includes('word')) return 'bi-file-earmark-word text-primary';
  if (fileType.includes('excel')) return 'bi-file-earmark-excel text-success';
  if (fileType.includes('image')) return 'bi-file-earmark-image text-warning';
  if (fileType.includes('text')) return 'bi-file-earmark-text';
  return 'bi-file-earmark';
};

const Documents: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const { token, username } = useSelector((state: RootState) => state.auth);
  const { documents, status, error, metadata } = useSelector((state: RootState) => state.docs);

  useEffect(() => {
    if (status === 'idle' && token && username) {
      dispatch(fetchMetaData({ token, username }));
    }
  }, [dispatch, status, token, username,location]);

  const handleDocumentClick = async (documentName: string) => {
    if (!token || !username) {
      console.error('Token or username is missing.');
      return;
    }

    let foundDoc = documents.find((doc: Document) => doc.name === documentName);

    if (!foundDoc) {
      try {
        const result = await dispatch(fetchOneDocument({ token, username, docName: documentName })).unwrap();
        if (result.length === 0) {
          console.error('Fetched document did not return data:', documentName);
          return;
        }
        foundDoc = result[0];
      } catch (error) {
        console.error('Error fetching document:', error);
        return;
      }
    }

    try {
      const base64Content = foundDoc.encodedContent.split(',')[1];
      if (!base64Content) {
        console.error('No base64 content found in encodedContent:', foundDoc.encodedContent);
        return;
      }

      const binaryData = Uint8Array.from(atob(base64Content), (char) => char.charCodeAt(0));
      const blob = new Blob([binaryData], { type: foundDoc.type });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = foundDoc.name;
      document.body.appendChild(link);
      link.click();

      URL.revokeObjectURL(url);
      link.remove();

      console.info(`Downloaded document: ${documentName}`);
    } catch (err) {
      console.error('Error handling document click:', err);
    }
  };

  if (!token || !username) return <p>Please log in to view documents.</p>;
  if (status === 'loading') return <p>Loading documents...</p>;
  if (status === 'failed') return <p>Error: {error}</p>;

  return (
    <>
      <Navbar />
      <div
        className="container mt-4"
        style={{
          backgroundImage: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <h2 style={{ zIndex: 2 }}>Documents</h2>

        <div className="document-grid d-flex flex-wrap gap-3" style={{ zIndex: 2 }}>
          {metadata &&
            metadata.length > 0 &&
            metadata.map((docMeta: Metadata, index: number) => (
              <div
                key={index}
                className="p-3 border rounded text-center"
                style={{
                  width: "150px",
                  cursor: "pointer",
                  backgroundColor: "rgba(255, 255, 255, 0.1)", // Transparent card background
                  backdropFilter: "blur(5px)", // Optional blur effect for enhanced transparency
                }}
                onClick={() => handleDocumentClick(docMeta.fileName)}
                title={docMeta.fileName}
              >
                <i className={`${getFileIcon(docMeta.fileType)} fs-1 mb-2`}></i>
                <p
                  className="mb-1 fw-bold"
                  style={{
                    fontSize: "14px",
                    margin: 0,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {stripExtension(docMeta.fileName)}
                </p>
                <p
                  className="mb-0 text-muted"
                  style={{
                    fontSize: "12px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {docMeta.md5hash} KB
                </p>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default Documents;
