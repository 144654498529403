import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { changePassword } from '../slices/auth'; // Ensure this action exists
import backgroundImage from '../assets/changePassword.webp'; // Adjust path as needed
import Navbar from '../components/Navbar';

const ChangePassword: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, error,username,token } = useSelector((state: RootState) => state.auth);

  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(changePassword({ currentPassword, newPassword ,token: token || '',user: username}));
  };

  return (
    <>
      <Navbar />
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
          margin: 0,
        }}
      >
        <div
          style={{
            maxWidth: '400px',
            width: '100%',
            padding: '30px',
            borderRadius: '10px',
            background: 'rgba(0,0,0,0.3)',
            color: '#fff',
            textAlign: 'center',
            boxShadow: '0 8px 32px 0 rgba(0,0,0,0.2)',
            backdropFilter: 'blur(5px)',
            border: '1px solid rgba(255,255,255,0.5)',
          }}
        >
          <h3 style={{ marginBottom: '20px' }}>Change Password</h3>
          {error && (
            <p
              style={{
                color: '#ff4d4f',
                background: 'rgba(255,0,0,0.1)',
                padding: '10px',
                borderRadius: '5px',
                marginBottom: '10px',
              }}
            >
              Could not change your password. Please try again.
            </p>
          )}
          <form onSubmit={handleSubmit}>
            <input
              type="password"
              placeholder="Current Password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              required
              style={{
                width: '100%',
                padding: '10px',
                marginBottom: '10px',
                border: '1px solid rgba(255, 255, 255, 0.7)',
                borderRadius: '5px',
                background: 'rgba(0, 0, 0, 0.4)',
                color: '#fff',
                outline: 'none',
                textAlign: 'center',
                fontSize: '16px',
              }}
            />
            <div style={{ position: 'relative', marginBottom: '20px' }}>
              <input
                type={showNewPassword ? 'text' : 'password'}
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                style={{
                  width: '100%',
                  padding: '10px',
                  border: '1px solid rgba(255, 255, 255, 0.7)',
                  borderRadius: '5px',
                  background: 'rgba(0, 0, 0, 0.4)',
                  color: '#fff',
                  outline: 'none',
                  textAlign: 'center',
                  fontSize: '16px',
                }}
              />
              <button
                type="button"
                onClick={() => setShowNewPassword(!showNewPassword)}
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  background: 'transparent',
                  border: 'none',
                  color: '#fff',
                  cursor: 'pointer',
                  fontSize: '14px',
                  padding: 0,
                }}
                aria-label="Toggle password visibility"
              >
                {showNewPassword ? 'Hide' : 'Show'}
              </button>
            </div>
            <button
              type="submit"
              disabled={isLoading}
              style={{
                width: '100%',
                padding: '10px',
                backgroundColor: isLoading ? 'rgba(150,150,150,0.8)' : 'rgba(0,123,255,0.8)',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: isLoading ? 'not-allowed' : 'pointer',
              }}
            >
              {isLoading ? 'Updating...' : 'Update Password'}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
