import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from '../webClient/axios';
import { MediaFile, Metadata } from './photos';
import { logout } from './auth';

export interface Document {
  name: string;
  size: number;
  type: string;
  encodedContent: string; // Base64 encoded content
}

interface DocumentsState {
  metadata: Metadata[];
  documents: Document[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: DocumentsState = {
  metadata: [],
  documents: [],
  status: 'idle',
  error: null,
};

// Thunk to fetch documents from an API
export const fetchDocuments = createAsyncThunk(
  'documents/fetchDocuments',
  async ({ token, username }: { token: string; username: string }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/documents/fetch-all-documents`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { username },
      });
      return response.data as Document[];
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to fetch documents.');
    }
  }
);

export const fetchOneDocument = createAsyncThunk<
  Document[], // Return a single MediaFile object
  { token: string; username: string; docName: string }
>(
  'media/fetchOneDocument',
  async ({ token, username, docName }, { rejectWithValue }) => {
    try {
      const response = await axios.get<Document[]>('/api/documents/fetch-document', {
        headers: { Authorization: `Bearer ${token}` },
        params: { user: username, doc: docName }, // Pass imageName as query param
      });
      return response.data; // Directly return the parsed MediaFile object
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Unable to fetch the photo');
    }
  }
);

export const fetchMetaData = createAsyncThunk<Metadata[], { token: string; username: string }>(
  'media/fetchMetaData',
  async ({ token, username }, { rejectWithValue }) => {
    try {
      const response = await axios.get<Metadata[]>('/api/catalog/search', {
        headers: { Authorization: `Bearer ${token}` },
        params: { user: username ,fileType: 'document'},
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Unable to fetch metadata');
    }
  }
);

const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMetaData.pending, (state) => {
        state.status = 'loading';
      });
      builder.addCase(fetchMetaData.fulfilled, (state, action: PayloadAction<Metadata[]>) => {
        state.status = 'succeeded';
        state.metadata = action.payload;
      });
      builder.addCase(fetchMetaData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch metadata';
      });
    builder
      .addCase(fetchDocuments.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.documents = action.payload;
      })
      .addCase(fetchDocuments.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
      builder
      .addCase(fetchOneDocument.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchOneDocument.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const addIfUnique = (doc:Document) => {
            // Check if the photo is already in the array (based on id or filename)
            return !state.documents.some(existingDoc =>  existingDoc.name === doc.name);
          };
        
          if (Array.isArray(action.payload)) {
            // Filter new photos to only include unique ones
            const uniqueDocs = action.payload.filter(addIfUnique);
            state.documents = [...state.documents, ...uniqueDocs];
          } else {
            // Add single photo if it is unique
            if (addIfUnique(action.payload)) {
              state.documents = [...state.documents, action.payload];
            }
          }
        state.documents = action.payload;
      })
      .addCase(fetchOneDocument.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
      builder.addCase(logout, () => initialState);
  },
  
});

export default documentsSlice.reducer;
