import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';



interface HlsPlayerProps {
  url: string;            // HLS URL to play
  token?: string;         // Bearer token or other auth token
  controls?: boolean;     // Enable or disable controls
  autoplay?: boolean;     // Auto-play video
  muted?: boolean;        // Start video muted
  style?: React.CSSProperties; // Optional inline styles for the video element
}

const HlsPlayer: React.FC<HlsPlayerProps> = ({
  url,
  token,
  controls = true,
  autoplay = true,
  muted = false,
  style = {},
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!videoRef.current) return;
    const videoElement = videoRef.current;

    if (Hls.isSupported()) {
      const hls = new Hls({
        xhrSetup: (xhr, url) => {
          if (token) {
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
          }
        },
      });

      hls.loadSource(url);
      hls.attachMedia(videoElement);

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        console.log('HLS manifest loaded');
        if (autoplay) {
          videoElement.play().catch((err) => console.error('Play error:', err));
        }
      });

      hls.on(Hls.Events.ERROR, (event, data) => {
        console.error('HLS error:', data);
      });

      return () => {
        hls.destroy();
      };
    } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      // In Safari (or iOS devices) where native HLS is supported directly, 
      // we cannot modify headers easily. The token should be included in the URL 
      // as a query parameter if required:
      // e.g. videoElement.src = `${url}?token=${encodeURIComponent(token)}`;
      videoElement.src = token ? `${url}?token=${encodeURIComponent(token)}` : url;
      if (autoplay) {
        videoElement.play().catch((err) => console.error('Play error:', err));
      }
    } else {
      console.error('HLS is not supported on this browser.');
    }
  }, [url, token, autoplay]);

  return (
    <video
      ref={videoRef}
      controls={controls}
      autoPlay={autoplay}
      muted={muted}
      style={{
        width: '100%',
        height: 'auto',
        ...style,
      }}
    />
  );
};




/*
interface HlsPlayerProps {
  url: string; // HLS URL to play
  controls?: boolean; // Enable or disable controls
  autoplay?: boolean; // Auto-play video
  muted?: boolean; // Start video muted
  style?: React.CSSProperties; // Optional inline styles for the video element
}

const HlsPlayer: React.FC<HlsPlayerProps> = ({
  url,
  controls = true,
  autoplay = false,
  muted = false,
  style = {},
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!videoRef.current) return;

    const videoElement = videoRef.current;

    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(url);
      hls.attachMedia(videoElement);

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        console.log('HLS manifest loaded');
        if (autoplay) videoElement.play().catch(console.error);
      });

      hls.on(Hls.Events.ERROR, (event, data) => {
        console.error('HLS error:', data);
      });

      return () => {
        hls.destroy();
      };
    } else if (videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      videoElement.src = url;
      if (autoplay) videoElement.play().catch(console.error);
    } else {
      console.error('HLS is not supported on this browser.');
    }
  }, [url, autoplay]);

  return (
    <video
      ref={videoRef}
      controls={controls}
      autoPlay={autoplay}
      muted={muted}
      style={{
        width: '100%',
        height: 'auto',
        ...style,
      }}
    />
  );
};

*/

export default HlsPlayer;
