import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store';
import { downloadVideo } from '../slices/download';
import background from "../assets/synctube.webp"; // Import the background image
import Navbar from '../components/Navbar';

const Synctube = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { token } = useSelector((state: RootState) => state.auth);
  const downloadState = useSelector((state: RootState) => state.download);

  const [videoUrl, setVideoUrl] = useState('');

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const data = e.dataTransfer.getData('text');
    setVideoUrl(data);
  };

  const handleDownload = () => {
    if (!videoUrl) {
      alert('Please provide a valid YouTube URL.');
      return;
    }

    if (!token) {
      alert('Please authenticate.');
      return;
    }

    dispatch(downloadVideo({ url: videoUrl, token }));
  };

  return (
    <>
    <Navbar/>
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
      }}
    >
      <div
        style={{
          maxWidth: '400px',
          width: '100%',
          padding: '20px',
          border: '1px solid rgba(255,255,255,0.5)',
          borderRadius: '10px',
          background: 'rgba(0,0,0,0.3)',
          color: '#fff',
          textAlign: 'center',
        }}
      >
        <h3 style={{ marginBottom: '20px' }}>YouTube Video Downloader</h3>
        <div
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
          style={{
            border: '2px dashed rgba(255,255,255,0.5)',
            padding: '20px',
            borderRadius: '5px',
            marginBottom: '10px',
            color: '#ddd',
            background: 'transparent'
          }}
        >
          Drop YouTube URL here
        </div>
        <input
          type="text"
          value={videoUrl}
          onChange={(e) => setVideoUrl(e.target.value)}
          placeholder="Or paste YouTube URL here"
          style={{
            width: '100%',
            padding: '10px',
            marginBottom: '10px',
            border: '1px solid rgba(255,255,255,0.5)',
            borderRadius: '5px',
            background: 'rgba(255,255,255,0.2)',
            color: '#fff',
            outline: 'none',
            textAlign: 'center', // center text within the input
          }}
        />
        <button
          onClick={handleDownload}
          style={{
            width: '100%',
            padding: '10px',
            backgroundColor: 'rgba(0,123,255,0.8)',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Download Video
        </button>
        <div
          style={{
            marginTop: '10px',
            padding: '10px',
            background: 'rgba(255,255,255,0.2)',
            border: '1px solid rgba(255,255,255,0.5)',
            borderRadius: '5px',
            color: '#fff',
          }}
        >
          {downloadState.status === 'loading' && <p>Downloading...</p>}
          {downloadState.status === 'succeeded' && (
            <p>Download completed! File saved at: {downloadState.filePath}</p>
          )}
          {downloadState.status === 'failed' && (
            <p style={{ color: 'red' }}>Error: {downloadState.error}</p>
          )}
        </div>
      </div>
    </div>
    </>
  );
};

export default Synctube;
