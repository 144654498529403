import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import axios from '../webClient/axios'
import { AppDispatch, RootState } from "../store"; // Import your AppDispatch type
import {
  uploadFilesRequest,
  uploadFilesSuccess,
  uploadFilesFailure,
} from "../slices/upload"; // Redux actions
import background from "../assets/filedrop.webp"; // Import the background image
import Navbar from "../components/Navbar";

const FileDropZone: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.auth);
  const [uploadStatus, setUploadStatus] = useState<string>("");
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (!token) {
        setUploadStatus("Authentication error: Token is missing");
        console.error("Authentication error: Token is missing");
        return;
      }

      setUploadStatus("Uploading...");
      dispatch(uploadFilesRequest());

      try {
        const formData = new FormData();
        acceptedFiles.forEach((file) => formData.append("files", file));

        const response = await axios.post<{ uploadedFiles: string[] }>(
          "/api/upload/multiPartUpload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`, // Include token in Authorization header
            },
          }
        );

        // Handle success
        console.log("Uploaded files:", response.data.uploadedFiles);
        const uploadedFilesList = response.data.uploadedFiles;
        setUploadStatus("Upload Successful!");
        setUploadedFiles(uploadedFilesList);

        // Dispatch success action
        dispatch(uploadFilesSuccess(uploadedFilesList));
      } catch (error: any) {
        setUploadStatus("Upload Failed!");
        console.error("Error uploading files:", error);

        // Dispatch failure action
        dispatch(uploadFilesFailure(error));
      }
    },
    [dispatch, token]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
  });

  return (
    <>
    <Navbar/>
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h2 className="text-center mb-4 text-white">File Upload</h2>
      <div
        {...getRootProps()}
        className="dropzone-container"
        style={{
          border: "2px dashed white",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          backdropFilter: "blur(5px)",
          borderRadius: "8px",
          padding: "2rem",
          textAlign: "center",
          color: "white",
          cursor: "pointer",
          width: "50%",
          maxWidth: "600px",
          maxHeight: "600px",
          transition: "background-color 0.3s",
        }}
      >
        <input
          {...getInputProps({
            webkitdirectory: true,
            directory: true,
          } as any)}
        />
        <i className="bi bi-cloud-upload display-4 mb-3"></i>
        <p className="mb-0">
          {isDragActive
            ? "Drop the files or directories here..."
            : "Drag & drop files or directories here, or click to select files"}
        </p>
      </div>
      <div className="mt-3 text-center text-white">{uploadStatus}</div>
      {uploadedFiles.length > 0 && (
  <div
    className="mt-4"
    style={{
      backgroundColor: "rgba(255, 255, 255, 0.2)", // Semi-transparent white background
      padding: "10px",
      borderRadius: "8px",
      maxHeight: "300px", // Limit the height
      overflowY: "auto", // Enable vertical scrolling if content exceeds maxHeight
    }}
  >
    <h5 className="text-white">Uploaded Files:</h5>
    <ul className="list-group">
      {uploadedFiles.map((file, index) => (
        <li
          key={index}
          className="list-group-item"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black for list items
            color: "white", // White text for contrast
            border: "1px solid rgba(255, 255, 255, 0.3)", // Border for better visibility
          }}
        >
          {file}
        </li>
      ))}
    </ul>
  </div>
)}

    </div>
    </>
  );
};

export default FileDropZone;
